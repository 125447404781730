.border-navbar {
  border: none;
  border-radius: 0px;
  /* border-right: 2px solid var(--bg-secondary); */
  border-bottom: solid 2px var(--bg-secondary);
  background-color: transparent;
  color: var(--bg-secondary);
  padding: 0px 10px 2px;
}

.navbar-link {
  color: var(--text-body);
  background: transparent;
  padding: 0px 10px 2px;
}

.selected-border {
  border: none;
  border-radius: 0.7rem;
  /* border-right: 2px solid var(--bg-secondary); */
  border-left: solid 2px var(--bg-secondary);
}

.terminal-card :hover {
  box-shadow: 2px 2px 4px 2px rgba(80, 80, 80, 0.1),
    0 6px 20px 0 rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.747);
  color: rgba(0, 0, 0, 0.856);
}

.terminal-card div :hover {
  transition: 0s;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0), 0 3px 10px 0 rgba(0, 0, 0, 0);
}
.border-terminal {
  border-bottom: solid 1px;
  background-color: #e9e9e929;
}
html {
  scroll-behavior: smooth;
}

.terminal-card a {
  color: black;
  scroll-behavior: smooth;
}
.refresh {
  transition: transform 0.3s ease-in-out;
}
.refresh:hover {
  transform: scale(1.03);
  color: rgba(0, 0, 0, 0.616);
}

.weekDays {
  min-width: 30px !important;
  max-width: 30%;

  height: 40px;
  background: #f04f23 !important;
  color: #fafafa;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
}

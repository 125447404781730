.modal-content-error {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #f8d7da;
    background-clip: padding-box;
    border-radius: 0.3rem;
    outline: 0;
    color: #842029;
}

.modal-content-sucess {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #d1e7dd;
    background-clip: padding-box;
    border-radius: 0.3rem;
    outline: 0;
    color: #0f5132;
}
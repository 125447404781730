.sidebar {
  padding: 20px 0px 20px 20px;
  width: 30vh;
  border-radius: 10px;
  background: var(--shape);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.16);
}

.sidebar-link {
  color: var(--text-body);
  background: transparent;
  padding: 10px 0px;
}
.sidebar-link:hover {
  color: var(--text-title);
}

.nav-pills .nav-link.active {
  background-color: transparent;
  color: var(--bg-secondary);
}

.border-sidebar {
  border: none;
  border-radius: 0px;
  /* border-right: 2px solid var(--bg-secondary); */
  border-right: solid 2px var(--bg-secondary);
  background-color: transparent;
  color: var(--bg-secondary);
  padding: 10px 0;
}

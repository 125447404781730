 td.BoletoCodigoBanco {
     font-size: 6mm;
     font-family: arial, verdana;
     font-weight: bold;
     FONT-STYLE: italic;
     text-align: center;
     vertical-align: bottom;
     border-bottom: 0.15mm solid #000000;
     border-right: 0.15mm solid #000000;
     padding-bottom: 1mm
 }

 td.BoletoLogo {
     border-bottom: 0.15mm solid #000000;
     border-right: 0.15mm solid #000000;
     text-align: center;
     height: 10mm;
     padding-bottom: 1mm
 }

 td.BoletoLinhaDigitavel {
     font-size: 4 mm;
     font-family: arial, verdana;
     font-weight: bold;
     text-align: center;
     vertical-align: bottom;
     border-bottom: 0.15mm solid #000000;
     padding-bottom: 1mm;
 }

 td.BoletoTituloEsquerdo {
     font-size: 0.2cm;
     font-family: arial, verdana;
     padding-left: 0.15mm;
     border-right: 0.15mm solid #000000;
     text-align: left
 }

 td.BoletoTituloDireito {
     font-size: 2mm;
     font-family: arial, verdana;
     padding-left: 0.15mm;
     text-align: left
 }

 td.BoletoValorEsquerdo {
     font-size: 3mm;
     font-family: arial, verdana;
     text-align: center;
     border-right: 0.15mm solid #000000;
     font-weight: bold;
     border-bottom: 0.15mm solid #000000;
     padding-top: 0.5mm
 }

 td.BoletoValorDireito {
     font-size: 3mm;
     font-family: arial, verdana;
     text-align: right;
     padding-right: 3mm;
     padding-top: 0.8mm;
     border-bottom: 0.15mm solid #000000;
     font-weight: bold;
 }

 td.BoletoTituloSacado {
     font-size: 2mm;
     font-family: arial, verdana;
     padding-left: 0.15mm;
     vertical-align: top;
     padding-top: 0.15mm;
     text-align: left
 }

 td.BoletoValorSacado {
     font-size: 3mm;
     font-family: arial, verdana;
     font-weight: bold;
     text-align: left
 }

 td.BoletoTituloSacador {
     font-size: 2mm;
     font-family: arial, verdana;
     padding-left: 0.15mm;
     vertical-align: bottom;
     padding-bottom: 0.8mm;
     border-bottom: 0.15mm solid #000000
 }

 td.BoletoValorSacador {
     font-size: 3mm;
     font-family: arial, verdana;
     vertical-align: bottom;
     padding-bottom: 0.15mm;
     border-bottom: 0.15mm solid #000000;
     font-weight: bold;
     text-align: left
 }

 td.BoletoPontilhado {
     border-top: 0.3mm dashed #000000;
     font-size: 1mm
 }

 ul.BoletoInstrucoes {
     font-size: 3mm;
     font-family: verdana, arial
 }
.relative-parent {
   background: var(--bg-primary);
}

.relative {
   height: 17rem;
   position: relative;
   top: 6rem;
   /* margin-bottom: 50rem;  */
}

.button-add-credit {
   font-size: 1rem;
   color: #fff;
   background: var(--bg-blue-light);
   border: 0;
   padding: 0 2rem;
   border-radius: 0.30rem;
   height: 3rem;
   transition: filter 0.2s;

   /* align-content: flex-end; */
}

button:hover {
   filter: brightness(0.9);
}

.blur {
   filter: blur(13px);
   -webkit-filter: blur(6px);
}
.cardLogin {
    border-radius: 1.0rem;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.31);
    border: none;
    justify-content: center;
    margin-bottom: 0.5em;
    min-height: 50%;
    max-width: 85vh;
    min-width: 45vh;
    padding: 35px 30px 35px 30px;

}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.btn-submit {
    width: 100%;
    background-color: #3F7FBF;
    transition: all 0.5s
}

.btn:hover {
    background-color: #308deb;
}

.forgetPassword {
    color: #bacdd8;
    transition: all 0.5s
}

.forgetPassword:hover {
    color: #eef8fd
}

.close {
    cursor: pointer;
}

.input {

    color: whitesmoke !important
}
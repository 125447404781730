.btn-filter {
  display: flex;
  justify-content: flex-end;
}

.circle-filter {
  justify-content: center;
  border-radius: 50%;
  align-items: center;
  width: 32px;
  height: 32px;
  background-color: white !important;
  color: black;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.31);
}
.circle-filter:hover {
  background-color: white !important;
  color: black;
}
.circle-filter:focus {
  color: black !important;
  border-color: rgba(255, 255, 255, 0.8);
  outline: 0 none;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.31) !important;
}

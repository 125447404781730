.center {
    margin: auto;
    padding: 15px;
}

#customerAreaMenu {
    color: black;
    text-decoration: none;
    background-color: white;
    border: 1px solid lightgray;
    border-right: none;
}

#customerAreaMenu :hover {
    background-color: rgba(0, 0, 0, 0.1);
}

#customerAreaMenu div :hover {
    background-color: transparent;
}


#OrderCards .OrderCards {
    box-shadow: 0 2px 4px 0 rgba(80, 80, 80, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

#OrderCards :hover {
    box-shadow: 0 4px 8px 0 rgba(80, 80, 80, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
    transform: scale(1.02);
    transition: 0.3s;
}

#OrderCards div :hover {
    background-color: transparent;
    transition: 0s;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.0), 0 6px 20px 0 rgba(0, 0, 0, 0.0);
    transform: scale(1);
}

#pagination .active a {
    color: #f3f2f3;
    background-color: #294081;
    border-color: #294081;
}

#pagination a {
    color: #294081;
}

#OrderCardsData {
    /* box-shadow: 0 2px 4px 0 rgba(80, 80, 80, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    border: 0px; */
}
.card {
  border-radius: 1rem;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.31);
  border: none;
  justify-content: center;
  margin-bottom: 1.5em;
}

.card-container {
  display: flex;
  /* flex-direction: row; */
  /* justify-content:flex-end; */
}
.card-link {
  color: var(--text-body);
}

.btn-card {
  border: none;
  /* border-top: 1px solid var(--bg-gray); */
  color: var(--text-body);
  background-color: transparent;
  border-radius: 0;
  width: 100%;
  padding-top: 0.25rem;
  margin-top: 0;
  padding-bottom: 0;
}

.btn-card:hover {
  border-color: var(--bg-gray);
  background: none;
  color: var(--bg-secondary);
}

.circle {
  width: 1rem;
  height: 1rem;
  background: var(--bg-green);
  margin: 0 1rem;
  padding: 0;
  border-radius: 50%;
}

.border-radius-2 div {
  border-radius: 1.5rem !important;
  border: none;
}

.btn-modal {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.75rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: #31444a;
  background-color: #e9ecef;
  border-color: #e9ecef;
}

.btn-modalActive {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.75rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: white !important;
  background-color: #37b24d;
  border-color: #e9ecef;
}
.card-footer {
  background-color: transparent;
}

.cardx{
    background-color:  #003366;
    border-radius: 0.5rem;
    color: white;
    width: 60vh;      
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.31);
    justify-content: center;
    margin-bottom: 0.5em;
    min-height: 32vh;
    max-width:60vh ;
    min-width:45vh ;
    padding: 35px 30px 35px 30px;   
   
}
@media (max-width: 500px) {
    .cardx {
        max-width:40vh ;
    }
  }

.center-card {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 90vh;

}

.form-control-CA {
    border: none;
    border-bottom: 2px solid var(--bg-gray);
    background-color: transparent;
    border-radius: 0;
    color: white
}

.btn-save {   

    width: 30%;
    background-color: #3F7FBF;
    transition: all 0.5s

}
